<template>
  <div></div>
</template>

<script>
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "../../../assets/build/vfs_fonts";
import apiConfig from "../../../config/api";

import moment from "moment";

import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import { toFixed } from "ant-design-vue/lib/input-number/src/utils/MiniDecimal";

import { ArabicNumberToText } from "../../../utils/numberToText";

import JsBarcode from "jsbarcode/bin/JsBarcode";

export default {
  props: {
    dataExport: Array,
  },
  data: () => ({
    newProduct: [],
    user: "",
    numberToTh: "",
  }),
  async created() {
    // console.log(this.dataExport);
    await this.getUser();
    await this.exports();
  },

  methods: {
    async getUser() {
      const userId = Decode.decode(localStorage.getItem("boss"));

      let responseData = [];

      try {
        responseData = await userApi.user.getOne(userId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.user = responseData.data;
      }
    },

    textToBase64Barcode(text) {
      let canvas = document.createElement("canvas");
      JsBarcode(canvas, text, { format: "CODE128" });

      return canvas.toDataURL("image/png");
    },

    exports() {
      const newWindow = window.open("", "_blank");

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: "Kanit-Regular.ttf",
          bold: "Kanit-Medium.ttf",
          italics: "Kanit-Medium.ttf",
          bolditalics: "Kanit-Medium.ttf",
        },
      };

      let html = [];

      if (Array.isArray(this.dataExport)) {
        this.dataExport.forEach((element, index) => {
          if (index === this.dataExport.length - 1) {
            html.push(
              {
                text: `${
                  element.product.product_name.length > 23
                    ? element.product.product_name.substring(0, 23) + "..."
                    : element.product.product_name
                }`,
                fontSize: 11,
                bold: true,
              },
              { text: `${element.expire_date}`, fontSize: 7 },
              { text: `${element.product.product_pog}`, fontSize: 7 },
              {
                table: {
                  widths: ["50%", "50%"],
                  body: [
                    [
                      {
                        text: "",
                        margin: [-5, 20, 0, 0],
                        fontSize: 5,
                        border: [false, false, false, false],
                      },
                      {
                        rowSpan: 2,
                        image: this.textToBase64Barcode(`${element.expire_no}`),
                        width: 85,
                        height: 35,
                        alignment: "right",
                        border: [false, false, false, false],
                        margin: [0, 0, -4, 5],
                      },
                    ],
                    [
                      {
                        text: `${element.product.product_code}`,
                        border: [false, false, false, false],
                        fontSize: 7,
                        margin: [-5, -15, 0, 0],
                      },
                      {
                        text: "",
                        border: [false, false, false, false],
                        fontSize: 5,
                      },
                    ],
                    [
                      {
                        text: `${element.product.product_barcode}`,
                        border: [false, false, false, false],
                        margin: [-5, -25, 0, 0],
                        fontSize: 7,
                      },
                      {
                        text: "",
                        border: [false, false, false, false],
                        fontSize: 5,
                      },
                    ],
                  ],
                },
              }
            );
          } else {
            html.push(
              {
                text: `${
                  element.product.product_name.length > 23
                    ? element.product.product_name.substring(0, 23) + "..."
                    : element.product.product_name
                }`,
                fontSize: 11,
                bold: true,
              },
              { text: `${element.expire_date}`, fontSize: 7 },
              { text: `${element.product.product_pog}`, fontSize: 7 },
              {
                table: {
                  widths: ["50%", "50%"],
                  body: [
                    [
                      {
                        text: "",
                        margin: [-5, 20, 0, 0],
                        border: [false, false, false, false],
                        fontSize: 5,
                      },
                      {
                        rowSpan: 2,
                        image: this.textToBase64Barcode(`${element.expire_no}`),
                        width: 85,
                        height: 35,
                        alignment: "right",
                        border: [false, false, false, false],
                        margin: [0, 0, -4, 0],
                      },
                    ],
                    [
                      {
                        text: `${element.product.product_code}`,
                        border: [false, false, false, false],
                        margin: [-5, -15, 0, 0],
                        fontSize: 7,
                      },
                      {
                        text: "",
                        border: [false, false, false, false],
                        fontSize: 5,
                      },
                    ],
                    [
                      {
                        text: `${element.product.product_barcode}`,
                        border: [false, false, false, false],
                        margin: [-5, -20, 0, 0],
                        fontSize: 7,
                      },
                      {
                        text: "",
                        border: [false, false, false, false],
                        fontSize: 5,
                      },
                    ],
                  ],
                },
                pageBreak: "after",
              }
            );
          }
        });
      } else {
        html.push(
          {
            // text: `${this.dataExport.product.product_name}`,
            text: `${
              this.dataExport.product.product_name.length > 23
                ? this.dataExport.product.product_name.substring(0, 23) + "..."
                : this.dataExport.product.product_name
            }`,
            fontSize: 11,
            bold: true,
          },
          { text: `${this.dataExport.expire_date}`, fontSize: 7 },
          { text: `${this.dataExport.product.product_pog}`, fontSize: 7 },
          {
            table: {
              widths: ["50%", "50%"],
              body: [
                [
                  {
                    text: "",
                    margin: [-5, 20, 0, 0],
                    border: [false, false, false, false],
                    fontSize: 5,
                  },
                  {
                    rowSpan: 2,
                    image: this.textToBase64Barcode(
                      `${this.dataExport.expire_no}`
                    ),
                    width: 85,
                    height: 35,
                    alignment: "right",
                    border: [false, false, false, false],
                    margin: [0, 0, -4, 0],
                  },
                ],
                [
                  {
                    text: `${this.dataExport.product.product_code}`,
                    border: [false, false, false, false],
                    margin: [-5, -15, 0, 0],
                    fontSize: 7,
                  },
                  {
                    text: "",
                    border: [false, false, false, false],
                    fontSize: 5,
                  },
                ],
                [
                  {
                    text: `${this.dataExport.product.product_barcode}`,
                    border: [false, false, false, false],
                    margin: [-5, -20, 0, 0],
                    fontSize: 7,
                  },
                  {
                    text: "",
                    border: [false, false, false, false],
                    fontSize: 5,
                  },
                ],
              ],
            },
          }
        );
      }

      const pdfData = {
        pageSize: {
          width: 142,
          height: 89,
        },
        pageMargins: [5, 5, 5, 5],
        // pageOrientation: "landscape",
        content: html,
      };
      pdfMake.createPdf(pdfData).open({}, newWindow);
      this.$emit("generatedFile");
    },
  },
};
</script>
